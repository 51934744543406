import React, { useEffect, useState } from "react";
import { BackIcon } from "../assets/Icons";
import RequestDetails from "./TrackRequestComponents/RequestDetails";
import { useNavigate } from "react-router-dom";
import { fetcher, getEmailsData } from "../utils/helper";
import Loader from "./common/Loader";
import { checkTitleColumn } from "./home/StepCard";

const TrackRequest = () => {
  const id = localStorage.getItem("subItemId");
  const count = localStorage.getItem("count");
  const siteSetting = JSON.parse(localStorage.getItem("settings"));
  const [itemData, setItemData] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [likeIds, setLikeIds] = useState([]);
  const [userEmailData, setUserEmailData] = useState([]);
  const navigate = useNavigate();

  const getSubItemData = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `/incorpify/getSubItemDetailsById/${id}`,
        "GET"
      );
      if (response.success) {
        setItemData(response?.data.response.data.items[0]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const getAllLikes = async () => {
    let ids = [];
    let likes = await fetcher("/incorpify/listAllLikes", "GET");
    if (likes.success) {
      likes.data.map((item) => {
        ids.push(item.item_type_id);
      });
    } else {
      ids = [];
    }

    setLikeIds(ids);
  };

  useEffect(() => {
    if (itemData?.column_values) {
      itemData?.column_values.forEach((item) => {
        if (item.id === "status__1") {
          setStatus(item.text);
        }
      });
    }
  }, [itemData]);
  useEffect(() => {
    getAllLikes();
    getSubItemData();
  }, []);

  useEffect(() => {
    if (itemData) {
      if (itemData.updates.length) {
        let users = [];
        itemData.updates.forEach((item) => {
          if (item.text_body.includes("From")) {
            let name = item.text_body.split(":")[0].split(" ")[1];
            users.push(name);
          }
        });
        if (users.length) {
          async function fetchUserFromEmails() {
            setLoading(true);
            let data = await getEmailsData(users);
            setUserEmailData([...data]);
            setLoading(false);
          }
          fetchUserFromEmails();
        }
      }
    }
  }, [itemData]);

  return (
    <>
      {loading && <Loader />}
      {itemData === "" ? (
        <></>
      ) : (
        <>
          <div className="w-100 text-start p-3 d-flex mt-5 align-items-center inc-request__head-wrapper">
            <div className="d-flex w-100 inc-request__title-wrapper">
              <div className="step-count fs-1 incorpify-request-heading">
                <span className="fw-bolder ff-ws step text-inc-tundora">
                  {count < 10 ? "0" + count : count}
                </span>
              </div>
              <div className="m-0 p-0 d-flex flex-column align-items-start justify-content-center incorpify-request-title">
                <p
                  className="fs-4 fw-bolder m-0 p-0 my-1 text-inc-tundora"
                  style={{ fontSize: "26px" }}
                >
                  {checkTitleColumn(itemData, siteSetting?.selectedColumn.title)}
                </p>
                <p
                  className={`status fw-bold ${
                    status === "Completed"
                      ? "text-success"
                      : status === "Stuck" || status.toLowerCase() === "pending" || status.toLowerCase() === "on hold"
                      ? "text-danger"
                      : status === "Locked" || status === "Awaiting Action"
                      ? "default"
                      : "text-warning"
                  } m-0 p-0`}
                  style={{ fontSize: "17px" }}
                >
                  {status}
                </p>
              </div>
            </div>
            <div
              className="inc-back-btn"
              style={{
                color: "#ff6221",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/request-list");
              }}
            >
              <BackIcon />
            </div>
          </div>
          <div className="mt-5">
            <RequestDetails
              data={itemData}
              getSubItemData={getSubItemData}
              loading={loading}
              setLoading={setLoading}
              likeIds={likeIds}
              getAllLikes={getAllLikes}
              requireAction={siteSetting?.selectedColumn.action}
              file={siteSetting?.selectedColumn.update}
              userEmailData={userEmailData}
              timeLineId={siteSetting?.selectedColumn.timeline}
              status={status}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TrackRequest;
